<template>
  <div>

    <v-card-text>
    <p class="display-1 text--primary" style="text-align: center;">
      Add new pricing for:<br>
      <span style="font-size:20px;">{{ $route.params.name }}</span>
    </p>

    <v-radio-group
      style="margin-top:0px;"
      v-model="formData.pricingMethod"
      label="Pricing Method*"
    >
      <v-radio
        label="Group"
        value="Group"
      ></v-radio>
      <v-radio
        label="Per Person"
        value="Per Person"
      ></v-radio>
    </v-radio-group>

    <br>

    <!-- <v-select
      label="Is this pricing for Receptive Only Operators"
      v-model="formData.receptiveSelected"
      :items="formData.receptiveOptions"
    >
    </v-select>

    <br> -->

    <v-textarea
      name="price description"
      filled
      counter
      label="Price description. Limit to 3-4 lines."
      maxlength='255'
      auto-grow
      v-model="formData.priceDescription"
    ></v-textarea>

    </v-card-text>

  </div>
</template>

<script>
export default {
  name: 'NewProduct',
  props: {
    parentData: {
      type: Object,
    },
    imgUrlOne: {
      type: String,
    },
    imgUrlTwo: {
      type: String,
    },
  },
  data() {
    return {
      formData: {
        pricingMethod: this.parentData.pricingMethod || '',
        priceDescription: this.parentData.priceDescription || '',
        receptiveSelected: 'No',
        receptiveOptions: ['No', 'Yes'],
      },
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    formData: {
      handler() {
        this.$emit('child-data', this.formData);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  img {
    width: 200px;
    height: 200px;
    margin: auto;
    display: block;
    margin-bottom: 10px
  }
</style>
