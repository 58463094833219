<template>
  <div style="flex-basis: 33.333333%">
    <v-card class="mx-auto my-12" max-width="374">

    <div v-if="cardPage === 1">
      <NewProduct :parentData='formData' @child-data="appendChildData" />
    </div>

    <div v-if="cardPage === 2">
      <GeneralPricing :parentData='formData' @child-data='appendChildData' />
    </div>

    <div v-if="cardPage === 3 && formData.pricingMethod === 'Per Person'">
      <SinglePricing :parentData='formData' @child-data='appendChildData' />
    </div>

    <div v-if="cardPage === 3 && formData.pricingMethod === 'Group'">
      <GroupPricing :parentData='formData' @child-data='appendChildData' />
    </div>

    <div v-if="cardPage === 4">
      <CompDetails :parentData='formData' @child-data='appendChildData' />
    </div>

    <v-card-text style="padding: 0 16px 9px 16px;">
    <p
      v-if='validationResponse.length > 0'
      style="border-left: 3px solid red; padding: 15px 0 15px 15px; margin: 0;"
      v-html="validationResponseFormatted"
    >
    </p>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-overlay
      :value="overlay"
      opacity="0.9"
      z-index="99"
    >
      <div style="max-width: 650px; min-height: 550px; width: 100%; background-color: #fff; color: #000; padding: 10px;">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="#283c86" text @click="overlay = false">X</v-btn>
        </v-row>
        <div style="display: flex; flex-direction: column; align-items: center; width: 98%;">
          <div style="margin-left: 30px;">
            <h2 style="margin-bottom: 3px">Confirm Pricing</h2>
            <p class="popupConfText">
              {{ this.$store.state.supplierName }} is submitting your information including, but not limited to contact details,
              location, products, pricing, and booking information, to Group Travel Odyssey (GTO) for
              addition as a supplier record. {{ this.$store.state.supplierName }} agrees to the following:
              <br><br>
              <ul>
                <li>All updates to any submitted information are the responsibility of {{ this.$store.state.supplierName }}.</li>
                <li>
                  Any errors in data entry by {{ this.$store.state.supplierName }} that result in incorrect calculations
                  in pricing by the GTO users are the responsibility of {{ this.$store.state.supplierName }}.
                </li>
                <li>
                  The supplier, products, and pricing will be visible to the entire GTO community
                  unless you specify otherwise. Please note this request for each product and/or
                  price you wish to restrict access. Restriction of access is only available for
                  products and pricing and within the limits of the GTO system.
                </li>
                <li>
                  After {{ this.$store.state.supplierName }} saves a product and/or price in GTO,
                  {{ this.$store.state.supplierName }} will not be
                  able to edit it. If you notice a discrepancy, please contact GTO immediately
                  to deactivate the product and/or pricing.
                </li>
              </ul>
            </p>
            <v-checkbox
              light
              v-model="checkboxpo"
              :label=stringCheckbox
            ></v-checkbox>
            <v-row style="position: absolute; bottom: 50px; width: 91%;">
              <v-btn color="#40832F" @click="overlay = false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="checkboxpo && !submitted"
                color="#40832F"
                @click="addPricing($route.params.id)"
              >
                Submit New Pricing
              </v-btn>
              <div v-if="submitted" style="display: flex; justify-content: center; width: 155px;">
                <v-progress-circular indeterminate :width="2" color="#4390ce"></v-progress-circular>
              </div>
            </v-row>
          </div>
        </div>
        <br>
      </div>
    </v-overlay>

    <v-card-actions>
      <v-btn v-if="cardPage > 1" color="#283c86" text @click="cardPage -= 1">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="cardPage < 4" color="#283c86" text @click="nextPage">Next</v-btn>
      <v-btn
        v-if="cardPage===4 && !submitted"
        color="#283c86" text
        @click="overlay = true"
      >
        Submit New Pricing
      </v-btn>
    </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import NewProduct from '../components/addpricing/NewProduct.vue';
import GeneralPricing from '../components/addpricing/GeneralPricing.vue';
import SinglePricing from '../components/addproduct/SinglePricing.vue';
import GroupPricing from '../components/addproduct/GroupPricing.vue';
import CompDetails from '../components/addproduct/CompDetails.vue';

export default {
  name: 'AddPricing',
  components: {
    NewProduct, GeneralPricing, SinglePricing, GroupPricing, CompDetails,
  },
  data() {
    return {
      checkboxpo: false,
      overlay: false,
      submitted: false,
      cardPage: 1,
      validationResponse: [],
      formData: {
        pricingMethod: this.$route.query.pricingMethod || '',
        priceDescription: this.$route.query.priceDescription || '',
        priceType: this.$route.query.priceType || [],
        groupMinimum: this.$route.query.groupMinimum || '',
        groupMaximum: this.$route.query.groupMaximum || '',
        complimentaryDetails: this.$route.query.complimentaryDetails || '',
        complimentaryType: this.$route.query.complimentaryType || '',
        complimentaryRatio: this.$route.query.complimentaryRatio || '',
        tdComplimentary: this.$route.query.tdComplimentary || '',
        driverComplimentary: this.$route.query.driverComplimentary || '',
        tax: this.$route.query.tax || '',
        fee: this.$route.query.fee || '',
        groupPrice: this.$route.query.groupPrice || '',
        veteranPrice: this.$route.query.veteranPrice || '',
        seniorPrice: this.$route.query.seniorPrice || '',
      },
      stringCheckbox: `By checking this box and submitting this request ${this.$store.state.supplierName} agrees to the above terms and conditions.`,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    validationResponseFormatted() {
      return `<b>Please fill out the following required fields:</b> <br>${this.validationResponse.map((x) => `${x}`).join('<br>')}`;
    },
  },
  methods: {
    nextPage() {
      if (this.cardPage === 1) {
        this.validationResponse = [];
        if (!this.formData.pricingMethod) this.validationResponse.push('Select a pricing method');
        if (!this.validationResponse.length) this.cardPage += 1;
      } else if (this.cardPage === 2) {
        this.validationResponse = [];
        if (!this.formData.priceType || !this.formData.priceType.length) this.validationResponse.push('Set a price type');
        if (!this.formData.priceValidDates || this.formData.priceValidDates.length < 2) this.validationResponse.push('Set your price valid dates');
        if (!this.validationResponse.length) this.cardPage += 1;
      } else {
        this.cardPage += 1;
      }
    },
    logdata() {
      console.log(this.formData);
    },
    addPricing(productId) {
      this.submitted = true;
      console.log(this.formData);
      const pricingData = { ...this.formData, productId };
      fetch(`${process.env.VUE_APP_API_URL}/api/create-pricing`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.token}` },
        body: JSON.stringify(pricingData),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.msg === 'Pricing Added.') {
            return this.$router.push({ path: '/products' });
          }
          console.log(response);
          this.submitted = false;
          return this.$store.commit('notify', 'Failed to add pricing. Please try again.');
        })
        .catch(() => {
          this.submitted = false;
          this.$store.commit('notify', 'Failed to add pricing. Please try again.');
        });
    },
    appendChildData(params) {
      this.formData = { ...this.formData, ...params };
    },
  },
};
</script>

<style scoped>
.popupConfText {
  height: auto;
  font-size: 14px;
  overflow-y: auto;
}
@media only screen and (max-width: 600px) {
  .popupConfText {
    height: 300px;
    background-color: rgb(236, 236, 236);
    padding: 3px;
    border-radius: 3px;
  }
}
</style>
